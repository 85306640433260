import React, { useContext } from "react"
import Text from '@typo/Text'
import StyledLink from '@typo/StyledLink'
import MainHeading from '@typo/MainHeading'
import styled from 'styled-components'
import headerImage from '@images/articles/erotic-audio-stories/blog-header.svg'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "@components/Seo";
import FAQContent from '@components/schemas/seoEroticAudioStories.json'
import Header from "@menus/header"
import { FirebaseContext } from '@components/Firebase'

const ArticleWrapper = styled.div`
  display:flex;
  justify-content: center;
  padding-top:90px;
  @media (min-width: 768px) {
    width:100%;
    padding-top:150px;
  }
`

const ArticleHeaderImage =  styled.img`
/* padding-top:30px; */
`

const ContentPaddingWrapper = styled.div`
  max-width:900px;
  padding: 0px 5% 100px 5%;
`

const FirstText =  styled(Text)`
 &:first-letter {
    text-transform:${props => props.title ? '' : 'uppercase'};
    font-size:${props => props.title  ? '' : '200%'};
    font-family:${props => props.title ? '' : props.theme.mainFontHeading};
    line-height: 1;
  }
  margin-bottom:50px;

`

const MetaData =  styled.p`
  font-family:${props => props.title ? '' : props.theme.mainFontParagraph};
  font-size:0.8rem;
  line-height:0.5;
`
const ListItemWrapper = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  padding-bottom:50px;
  align-items: center;

`

const ItemImageWrapper = styled.div`
  width:100%;
  @media (min-width: 768px) {
    width:100%;
  }
`

const ItemTextColumn = styled.div`

  
`
const ItemText = styled.div`
  font-family: ${props => props.theme.mainFontParagraph},'Arial';
  line-height: 1.6;
  h4 {
    margin: 0px;
    font-size: 1.4rem;
  }

  a {
    color: ${props => props.theme.secondaryColor};
  }
`

const ItemCta = styled(Text)`
  font-family:'Poppins';
`
const ItemMetaInfo = styled(Text)`
  line-height:1;
`
const SubHeadline = styled(Text)`
  padding-bottom:10px;
  font-style:italic;
`

const EroticAudio = styled.span`
  font-size:14px; 
  font-family:'Poppins';
  padding-left:3px;
  display: block;
`

const ItemHeadline = styled.h2`
  font-size: 2.5rem;
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
 
  }
`
const FAQItemHeadline = styled.h3`
  font-size: 2rem;
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
 
  }
`

const HeaderTitle = styled.h2`
  ${props => props.topPadding ? 'padding-top: 100px;' : ''}
  font-size: 3rem;
  ${props => props.center ? 'text-align:center;' : ''}
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
    text-align:center;
  }
`

const content = {
  "in-love-with-you":{
    "subtitle":"Romantic Erotic Audio:",
    "text":"This romantic and sensual audio story details a morning in bed, reading a sexy love letter from your partner after he has already left. He confesses his love and recounts all the exciting and erotic things you did last night, in every naughty little detail. This 9-minute quickie romance is sure to get you and your lover in the mood for some sensual bonding time.",
    "rank":10
  },
  "yoga-instructor-1":{
    "subtitle":"Public Sex Erotic Audio:",
    "text":"Part I of this yoga-centric erotic audio series has you noticing just how sexy your new yoga instructor is - and allowing him to give you something of a private lesson. Told from the point of view of the yoga instructor, you get a glimpse into how he sees you (and what he wants to do with you) during your first yoga class. This 15-minute naughty story will get you in the mood to do some erotic sretching with your lover.",
    "rank":9
  },
  "handyman-bondage":{
    "subtitle":"Bondage Erotic Audio:",
    "text":"You get an unexpected visit from the very attractive handyman for your building in this steamy Erotic Audio. You take him downstairs to do his work, but it appears you both have an interest in bondage games, which is made abundantly clear in your flirty banter. This 10-minute Erotic Audio is sure to get you in the mood for something a little more adventurous tonight.",
    "rank":8
  },
  "anniversary-surprise":{
    "subtitle":"Romantic Erotic Audio:",
    "text":"This erotic story explores a very sexy anniversary surprise laid out for you by your boyfriend. He has everything planned - the rose petals, the candles, the blindfold and the handcuffs...tonight, he’s going to show you what an amazing partner you are. This 11-minute story is full of intense and erotic moments that will get you in the mood.",
    "rank":7
  },
  "new-professor-part-1":{
    "subtitle":"Teacher-Student Erotic Audio:",
    "text":"You stumble in late to class and issue an apology to your new professor, and immediately, you feel drawn to him. He’s tall, gorgeous and in total command of the room. Through a series of events, he becomes your Dominant, helping you explore the blissful adventures of dominance and submission. The power dynamic between the two of you as teacher and professor makes your erotic and intimate encounters that much more intense. This 10-minute opener to the “New Professor” series will get your heart racing in all the best ways.",
    "rank":6, 
  },
  "late-night-jogging":{
    "subtitle":"Stranger Erotic Audio:",
    "text":"It’s dark outside and late in the evening, you’re enjoying some fresh air (and a glass of wine) on your front porch. Suddenly, a very handsome jogger runs into your street. His shirt is soaked from the light, warm rain and plastered against his gorgeous chest and abs. You watch him approach you and your mind starts to wander. This 12-minute epic fantasy is enough to get anyone hot under the collar!",
    "rank":5
  },
  "sauna-sex":{
    "subtitle":"Stranger Erotic Audio:",
    "text":"You notice a sexy stranger making eyes at you during your workout at the fitness center you’ve just signed up for. Your mind wanders to naughty places with him during your workout. You decide to enjoy the sauna at your fitness center and are stunned to see him sitting in there by himself as you step inside. Things get wet and wild with a sexy stranger in this 13-minute steamy Erotic Audio.",
    "rank":4
  },
  "lecturer-1":{
    "subtitle":"Teacher Student Erotic Audio:",
    "text":"You have an appointment to discuss your latest assignment with your lecturer. During class, you can’t help but admire her - her body, her voice, the way she commands the room. You notice her gaze settling on you and hope she’s thinking about the same things you are. Your appointment proves to be equally pleasurable for you both as you get lost in each other. This 9-minute lesbian Erotic Audio is everyone’s naughtiest teacher-student fantasy come true.",
    "rank":3
  },
  "phone-sex-business-trip":{
    "subtitle":"Romantic Phone Sex Erotic Audio:",
    "text":"You’re away at a business conference and are finally back in your hotel room, ready to unwind for the evening. You decide to call your husband and see how he’s doing at the house without you, and things quickly turn hot and steamy when he says he may have to touch himself while you talk, since he’s missed you so much. You’re intrigued, and play along, letting the intimate conversation lead as your hands explore your own body to the sound of his voice. This 8-minute Erotic Audio is great for couples who want to stay connected.",
    "rank":2
  },
  "guy-next-door-1":{
    "subtitle":"Romantic Friends-to-Lovers Erotic Audio:",
    "text":"It feels so comforting and familiar to be back in your family home after so many years studying abroad. Memories come flooding back as you wander around the house. You think about Carl, the guy next door. You think about his lips, his warm brown eyes, his strong arms and his sincere smile. You recall a very specific Thanksgiving, six years ago, when he was right there in that kitchen with you. As you lean back in your chair, your mind (and your hands) wander where they may, thinking about that sweet, sexy guy next door. This 20-minute self-love session has something for everyone.",
    "rank":1
  },

}


const articleSchema = {
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "headline": "Top 10 Erotic Audio Stories You Absolutely Need to Listen to in 2021",
  "image": [
    "https://audiodesires.com/images/erotic-audio-stories/top-10-erotic-audio-stories-audiodesires-1-1.jpg",
    "https://audiodesires.com/images/erotic-audio-stories/top-10-erotic-audio-stories-audiodesires-4-3.jpg",
    "https://audiodesires.com/images/erotic-audio-stories/top-10-erotic-audio-stories-audiodesires-16-9.jpg"
   ],
  "datePublished": "2020-12-02",
  "dateModified": "2020-12-02",
  "isAccessibleForFree":true,
  "author": {
    "@type": "Person",
    "name": "Jaimee Bell"
  },
   "publisher": {
    "@type": "Organization",
    "name": "Audiodesires",
    "logo": {
      "@type": "ImageObject",
      "url": "https://audiodesires.com/images/logos/audiodesires_black.png"
    }
  },
  "mainEntityOfPage":{
    "@type":"WebPage",
    "@id":"https://audiodesires.com/erotic-audio-stories/"
 }
}

const EroticAudioStories = (props) => {
  const {user} = useContext(FirebaseContext);
  const lang = props.pageContext.lang

  const convertArrayToObject = (array, key, content) => {
    const initialValue = [];
    return array.reduce((obj, item) => {
      return [...obj,{...content[item.en[key]],...item}]
    }, initialValue);
  };
  const Stories =  convertArrayToObject(props.data.allStory.nodes, 'slug', content)

  return <>
  <Header boxLayout={false} playerMode={'small'} user={user} alwaysShow={true}/>
  <ArticleWrapper>
    <Seo 
      title={"Top 10 Erotic Audio Stories to listen to in 2021"}
      description={"Erotic audio stories for women ✅ Professional audio erotica ✅ Different stories and fantasies ✅ 5-20 min ✅ Free stories available ✅ Respectful & relatable"}
      structuredData={[articleSchema, FAQContent]}
    />
    <ContentPaddingWrapper>
      
      <MainHeading>{articleSchema.headline}</MainHeading>
      <SubHeadline>Relax, listen, climax - it’s that easy. </SubHeadline>
      <MetaData>Last Updated on December 2, 2020 </MetaData>
      <MetaData>by {articleSchema.author.name} </MetaData>
      <ArticleHeaderImage src={headerImage} alt="Erotic Audio Stories by Audiodesires"/>
      <FirstText>
        Erotic audio stories are on the rise and I couldn’t be happier about it. Putting my intense voice kink aside for a moment, erotic audio stories are the best ways for women and couples to consume erotic content. Erotic audio stories allow you to create a world entirely your own. Instead of watching someone else experience pleasure, you (and your partner) can enjoy subtle and sensual stories that are crafted to enhance your imagination for the ultimate erotic experience.
        <br/><br/>
        Audiodesires offers erotic audio stories for women and couples to explore together or separately. With exciting and erotic voices, our professional speakers take you on a pleasurable journey through a story of your choosing. Exploring new kinks, sexual adventures and each other has never been more enjoyable. 
        <br/><br/>
        Below are the top 10 erotic audio stories you absolutely need to listen to before the years’ end. These rankings are created based on ratings they’ve been given by listeners just like you. 
      </FirstText>
      {Stories.sort((a,b) =>  b.rank - a.rank ).map((story) => (
        <ListItemWrapper key={story.rank}>
          
          <ItemImageWrapper>
            <Link to={`/story/${story.en.slug}`}>
              <GatsbyImage
                image={story.localImage.childImageSharp.gatsbyImageData}
                alt={`${story.en.title} - Erotic Audio Story by Audiodesires`} />
            </Link>
          </ItemImageWrapper>
          <ItemTextColumn>
            <ItemHeadline><EroticAudio>{story.subtitle}</EroticAudio>{story.rank}. {story.en.title}</ItemHeadline>
            <ItemMetaInfo>Narrator: <StyledLink to={`/voice/${story[lang].voices[0].name.toLowerCase()}`}>{story[lang].voices[0].name}</StyledLink></ItemMetaInfo>
            <ItemText dangerouslySetInnerHTML={{ __html: content[story.en.slug].text}}/>
            <ItemCta>Listen to <StyledLink to={`/story/${story.en.slug}`}>{story.en.title}</StyledLink>{} now.</ItemCta>
          </ItemTextColumn>
        </ListItemWrapper>
      
      ))}
      <HeaderTitle center >Frequently Asked Questions (FAQ) about Erotic Audio Stories</HeaderTitle>
      {FAQContent.mainEntity.map((edge,index) =>(
        <ListItemWrapper key={index}>
          <ItemTextColumn>
          <FAQItemHeadline>{edge.name}</FAQItemHeadline>
          <ItemText dangerouslySetInnerHTML={{ __html: edge.acceptedAnswer.text}}/>
          </ItemTextColumn>
        </ListItemWrapper>
      ))}
    </ContentPaddingWrapper>
  </ArticleWrapper>
</>;
}

export default EroticAudioStories
export const query = graphql`{
  allStory(
    filter: {en: {slug: {in: ["in-love-with-you", "yoga-instructor-1", "handyman-bondage", "anniversary-surprise", "new-professor-part-1", "late-night-jogging", "sauna-sex", "lecturer-1", "phone-sex-business-trip", "guy-next-door-1"]}}}
  ) {
      nodes {
        ...seoBlogPostStoryInfo 
      }
  }
}
`
